<template>
  <div class="hoge">
    <ms-toolbar></ms-toolbar>
    <v-container class="ms-apps-content">
      <apps-list
        @app-clicked="appClicked"
      ></apps-list>
    </v-container>
  </div>
</template>

<script>
import Common from './common.js'
import MsToolbar from './ms_toolbar.vue'
import AppsList from './apps_list.vue'

export default {
  components: {
    'ms-toolbar': MsToolbar,
    'apps-list': AppsList,
  },
  methods: {
    appClicked(app) {
      this.$router.push('/apps/' + app.id)
    }
  }
}
</script>

<style scoped>
.ms-apps-content {
  width: 100%;
  max-width: 768px;
  padding: 0;
}
</style>
