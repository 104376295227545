<template>
  <div>
    <ms-toolbar></ms-toolbar>
    <v-container class="ms-apps-new-content">


      <v-container class="ms-controls">
        <v-row>
          <v-spacer></v-spacer>
          <v-btn dark depressed @click="saveButtonClicked" class="ms-button"><v-icon>save</v-icon>Save</v-btn>
          <v-btn dark depressed @click="cancelButtonClicked" class="ms-button-last"><v-icon>cancel</v-icon>Cancel</v-btn>
        </v-row>
      </v-container>


      <v-container class="ms-content">

        <v-alert :value="errors" color="error" icon="warning" transition="scale-transition">
          <template v-for="e in errors">{{ e }}<br></template>
        </v-alert>

        <apps-app
          :app="app"
          :editing="true"
        ></apps-app>

        <ms-file-container
          ref="msFileContainer"
          :editing="true"
          :name="app.file_name"
          :url="app.file_url"
          :types="app.app_type == 'android' ? ['apk'] : ['ipa']"
        ></ms-file-container>

      </v-container>


      <ms-activity-dialog ref="msActivityDialog"></ms-activity-dialog>


    </v-container>
  </div>
</template>

<script>
import Common from './common.js'
import MsToolbar from './ms_toolbar.vue'
import AppsApp from './apps_app.vue'
import MsFileContainer from './ms_file_container.vue'
import MsActivityDialog from './ms_activity_dialog.vue'

export default {
  components: {
    'ms-toolbar': MsToolbar,
    'apps-app': AppsApp,
    'ms-file-container': MsFileContainer,
    'ms-activity-dialog': MsActivityDialog
  },
  data() {
    return {
      errors: null,
      app: {
        title: "MirrorSnap",
        app_type: 'ios',
        bundle_identifier: "jp.co.dawgs.MirrorSnap",
        bundle_version: "6.1",
        note: null
      }
    }
  },
  methods: {
    saveButtonClicked() {
      this.postApp()
    },
    cancelButtonClicked() {
      this.$router.go(-1)
    },
    postApp() {
      let params = {
        app: this.app
      }
      this.$refs.msActivityDialog.showDialog()
      Common.axios(document).post('/apps', params).then(response => {
        this.app = response.data.app
        let result = this.$refs.msFileContainer.result()
        if (result) {
          this.uploadFile(result)
          return
        }
        this.$router.push('/apps/' + this.app.id + '?created=true')
      }).catch(error => {
        console.log(error)
        if (401 == error.response.status) {
          this.$router.push('/sign_in')
        }
        this.errors = []
        let errors = error.response.data.errors
        if (errors.title) {
          errors.title.forEach(e => { this.errors.push('タイトル' + e) })
        }
        if (errors.bundle_identifier) {
          errors.bundle_identifier.forEach(e => { this.errors.push('bundle-identifier' + e) })
        }
        if (errors.bundle_version) {
          errors.pref_code.forEach(e => { this.errors.push('bundle-version' + e) })
        }
        this.$refs.msActivityDialog.hideDialog()
      })
    },
    uploadFile(result) {
      let formData = new FormData();
      formData.append('file', result)
      let config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      Common.axios(document).post('/apps/' + this.app.id, formData, config).then(response => {
        this.$router.push('/apps/' + this.app.id + '?created=true')
      }).catch(error => {
        console.log(error)
        if (401 == error.response.status) {
          this.$router.push('/sign_in')
        }
        this.$refs.msActivityDialog.hideDialog()
      })
    }
  },
  mounted() {
    if (Common.role() != 'admin') {
      this.router.push('/')
    }
  }
}
</script>

<style scoped>
.ms-apps-new-content {
  width: 100%;
  max-width: 768px;
  padding: 0;
}
.ms-controls {
  position: fixed;
  top: 56px;
  width: 100%;
  max-width: 756px;
  padding: 16px;
  background-color: #121212;
  z-index: 100;
}
.ms-button {
  margin: 0 0 0 8px;
}
.ms-button-last {
  margin: 0 12px 0 8px;
}
.ms-content {
  width: calc(100% - 32px);
  margin-top: 68px;
  padding: 0;
  z-index: -100;
}
</style>
