<template>
  <v-dialog v-model="show" width="300px" overlay-color="#121212" overlay-opacity="0.8">
    <v-container class="ms-container">
      <h4 class="ms-title">削除してよろしいですか？</h4>
      <p class="ms-text">{{ message }}</p>
      <v-row class="ms-button-row">
        <v-spacer></v-spacer>
        <v-btn dark depressed @click="cancelButtonClicked">キャンセル</v-btn>
        <v-btn dark depressed @click="deleteButtonClicked" class="ms-button">削除</v-btn>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: '一度削除すると元に戻せません。'
    }
  },
  methods: {
    showDialog(message) {
      if (message) {
        this.message = message
      }
      this.show = true
    },
    cancelButtonClicked() {
      this.show = false
    },
    deleteButtonClicked() {
      this.$emit('delete-button-clicked')
      this.show = false
    }
  }
}
</script>

<style scoped>
.ms-container {
  background-color: #323232;
}
.ms-title {
  margin: 12px;
}
.ms-text {
  margin: 12px;
  font-size: 14px;
}
.ms-button-row {
  margin: 24px 8px 0;
}
.ms-button {
  margin-left: 8px;
}
</style>
