<template>
  <v-container v-if="app">

    <v-row>
      <div class="ms-text-field-container">
        <v-text-field
          v-model="app.title"
          label="TITLE"
          hint="ex) MirrorSnap案件フレーム変更バージョン"
          :clearable="editing"
          :disabled="!editing"
        ></v-text-field>
      </div>
    </v-row>

    <v-layout row>
      <div class="ms-text-field-container">
        <v-select
          v-model="app.app_type"
          label="TYPE"
          :items="appTypes"
          :disabled="!editing"
        ></v-select>
      </div>
    </v-layout>

    <v-layout row v-if="app.app_type == 'ios'">
      <div class="ms-text-field-container">
        <v-text-field
          v-model="app.bundle_identifier"
          label="BUNDLE-IDENTIFIER"
          hint="ex) jp.co.dawgs.MirrorSnap"
          :clearable="editing"
          :disabled="!editing"
        ></v-text-field>
      </div>
    </v-layout>

    <v-layout row v-if="app.app_type == 'ios'">
      <div class="ms-text-field-container">
        <v-text-field
          v-model="app.bundle_version"
          label="BUNDLE-VERSION"
          hint="ex) 4.3"
          :clearable="editing"
          :disabled="!editing"
        ></v-text-field>
      </div>
    </v-layout>

    <v-layout row>
      <div class="ms-text-field-container">
        <v-textarea
          v-model="app.note"
          label="NOTE"
          auto-grow
          rows="1"
          :clearable="editing"
          :disabled="!editing"
        ></v-textarea>
      </div>
    </v-layout>

  </v-container>
</template>

<script>
export default {
  props: [
    'app',
    'editing'
  ],
  data() {
    return {
      appTypes: [
        { value: 'ios', text: 'iOS' },
        { value: 'android', text: 'Android' }
      ]
    }
  }
}
</script>

<style scoped>
.ms-text-field-container {
  width: 100%;
  margin: 0 12px 12px;
}
</style>
