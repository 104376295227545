<template>
  <div class="ms-apps-list">


    <v-container class="ms-controls">

      <v-row>
        <div class="ms-used" v-if="used">DISK USED {{ used }}</div>
        <v-spacer></v-spacer>
        <v-btn v-if="!selecting" dark depressed @click="addButtonClicked" :disabled="!isAdmin" class="ms-button">
          <v-icon v-if="showButtonIcon">add</v-icon>Add
        </v-btn>
        <v-btn v-if="!selecting" dark depressed @click="selectButtonClicked" :disabled="!isAdmin" class="ms-button-last">
          <v-icon v-if="showButtonIcon">delete</v-icon>Select
        </v-btn>
        <v-btn v-if="selecting" dark depressed @click="deleteButtonClicked" :disabled="!isAdmin" class="ms-button">
          <v-icon v-if="showButtonIcon">delete</v-icon>Delete
        </v-btn>
        <v-btn v-if="selecting" dark depressed @click="cancelButtonClicked" :disabled="!isAdmin" class="ms-button-last">
          <v-icon v-if="showButtonIcon">cancel</v-icon>Cancel
        </v-btn>
      </v-row>

      <v-row>
        <div class="ms-search-text-container">
          <v-text-field
            class="ms-button"
            label="SEARCH"
            v-model="q"
            @keyup.enter="searchButtonClicked"
            append-icon="search"
            @click:append="searchButtonClicked"
            clearable
          ></v-text-field>
        </div>
        <v-spacer></v-spacer>
        <div class="ms-order-button-container">
          <v-menu bottom left offset-y>
            <template v-slot:activator="{ on }">
              <v-btn dark icon v-on="on"><v-icon>sort</v-icon></v-btn>
            </template>
            <v-list>
              <v-list-item @click="orderItemClicked('id')">
                <v-list-item-title class="ms-order-item-title">
                  ID
                  <v-icon small v-if="order == 'id' && asc">arrow_upward</v-icon>
                  <v-icon small v-if="order == 'id' && !asc">arrow_downward</v-icon>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="orderItemClicked('title')">
                <v-list-item-title class="ms-order-item-title">
                  TITLE
                  <v-icon small v-if="order == 'title' && asc">arrow_upward</v-icon>
                  <v-icon small v-if="order == 'title' && !asc">arrow_downward</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-row>

    </v-container>


    <v-container class="ms-list">
      <v-row v-for="a in apps" @click="appClicked(a)" :class="rowClass(a)">
        <div class="ms-col-id">
          <div>{{ a.id }}</div>
        </div>
        <div class="ms-col-content">
          <div class="ms-col-name">{{ a.title }}</div>
          <div class="ms-col-note">{{ a.note }}</div>
        </div>
      </v-row>
    </v-container>


    <ms-activity-dialog ref="msActivityDialog"></ms-activity-dialog>
    <ms-delete-dialog ref="msDeleteDialog" @delete-button-clicked="dialogDeleteClicked"></ms-delete-dialog>

  </div>
</template>

<script>
import Common from './common.js'
import MsActivityDialog from './ms_activity_dialog.vue'
import MsDeleteDialog from './ms_delete_dialog.vue'

export default {
  components: {
    'ms-activity-dialog': MsActivityDialog,
    'ms-delete-dialog': MsDeleteDialog
  },
  data() {
    return {
      showButtonIcon: window.innerWidth >= 400,
      selecting: false,
      isAdmin: Common.role() == 'admin',
      apps: null,
      used: null,
      q: '',
      order: 'id',
      asc: false,
      selected: []
    }
  },
  methods: {
    rowClass(app) {
      let index = this.selected.findIndex(id => id == app.id)
      if (0 <= index) {
        return "ms-row-selected"
      }
      return "ms-row"
    },
    addButtonClicked() {
      this.$router.push('/apps/new')
    },
    selectButtonClicked() {
      this.selected = []
      this.selecting = true
    },
    cancelButtonClicked() {
      this.selected = []
      this.selecting = false
    },
    deleteButtonClicked() {
      if (this.selected.length <= 0) {
        return
      }
      let message = '' + this.selected.length + '個のアプリが選択されています。'
      this.$refs.msDeleteDialog.showDialog(message)
    },
    dialogDeleteClicked() {
      this.deleteApps()
    },
    searchButtonClicked() {
      this.getApps()
    },
    orderItemClicked(order) {
      if (this.order == order) {
        this.asc = !this.asc
      } else {
        this.order = order
        this.asc = true
      }
      this.getApps()
    },
    appClicked(app) {
      if (!this.selecting) {
        this.$emit('app-clicked', app)
        return
      }
      let index = this.selected.findIndex(id => id == app.id)
      if (index < 0) {
        this.selected.push(app.id)
      } else {
        this.selected.splice(index, 1)
      }
    },
    getApps() {
      let params = {
        q: this.q,
        order: this.order,
        asc: this.asc
      }
      this.$refs.msActivityDialog.showDialog()
      Common.axios(document).get('/apps', { params: params }).then(response => {
        this.apps = response.data.apps
        if (window.innerWidth >= 360) {
          this.used = response.data.used
        }
        this.$refs.msActivityDialog.hideDialog()
      }).catch(error => {
        if (401 == error.response.status) {
          this.$router.push('/sign_in')
        }
        this.$refs.msActivityDialog.hideDialog()
      })
    },
    deleteApps() {
      let params = {
        ids: this.selected
      }
      this.$refs.msActivityDialog.showDialog()
      Common.axios(document).delete('/apps?ids=' + this.selected.join(',')).then(response => {
        location.reload()
      }).catch(error => {
        if (401 == error.response.status) {
          this.$router.push('/sign_in')
        }
        this.$refs.msActivityDialog.hideDialog()
      })
    }
  },
  mounted() {
    this.getApps()
  }
}
</script>

<style scoped>
.ms-apps-list {
  width: 100%;
}
.ms-controls {
  position: fixed;
  top: 56px;
  width: 100%;
  max-width: 756px;
  padding: 16px 16px 0;
  background-color: #121212;
}
.ms-used {
  margin: 8px 0 0 16px;
  padding: 0;
  height: 100%;
  font-size: 14px;
}
.ms-button {
  margin: 0;
}
.ms-button-last {
  margin: 0 12px 0 8px;
}
.ms-search-text-container {
  width: 75%;
  max-width: 400px;
  margin: 8px 16px 0;
}
.ms-order-button-container {
  margin: 20px 8px 0;
}
.ms-order-item-title {
  width: 88px;
}
.ms-list {
  width: calc(100% - 32px);
  margin-top: 126px;
  padding: 0;
  border-top: solid 1px #616161;
}
.ms-row {
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: solid 1px #616161;
}
.ms-row:hover {
  background-color: #323232;
}
.ms-row-selected {
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: solid 1px #616161;
  background-color: #424242;
}
.ms-col-id {
  width: 72px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ms-col-content {
  width: calc(100% - 72px);
  padding: 8px;
}
.ms-col-name {
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ms-col-note {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
