<template>
  <v-layout row justify-center align-center class="ms-sign-in">
    <v-container class="ms-content">

      <v-layout row>
        <img src="./images/logo.png" class="ms-logo-image"></img>
      </v-layout>

      <v-layout row justify-center class="ms-title-row">
        <h1 class="ms-title">ms.snsap.net</h1>
      </v-layout>

      <v-form @submit="onSubmit">

        <v-alert :value="errors != null" transition="scale-transition">
          <ul><li v-for="e in errors">{{ e }}</li></ul>
        </v-alert>

        <v-layout row class="ms-text-field-row">
          <v-text-field label="EMAIL" v-model="email" type="email" clearable></v-text-field>
        </v-layout>

        <v-layout row class="ms-text-field-row">
          <v-text-field
            label="PASSWORD"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
        </v-layout>

        <v-layout justify-center row>
          <v-btn large depressed type="submit" color="#212121" class="ms-sign-in-button">Sign in</v-btn>
        </v-layout>

      </v-form>

      <ms-activity-dialog ref="msActivityDialog"></ms-activity-dialog>

    </v-container>
  </v-layout>
</template>

<script>
import Common from './common.js'
import MsActivityDialog from './ms_activity_dialog.vue'

export default {
  components: {
    'ms-activity-dialog': MsActivityDialog
  },
  data() {
    return {
      showPassword: false,
      email: Common.email(),
      password: '',
      errors: null
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      this.errors = null
      let params = {
        email: this.email,
        password: this.password
      }
      this.$refs.msActivityDialog.showDialog()
      Common.axios(document).post('/auth/sign_in', params).then(response => {
        Common.saveEmail(this.email)
        Common.saveAccessToken(response.headers['access-token'])
        Common.saveUid(response.headers['uid'])
        Common.saveClient(response.headers['client'])
        Common.saveRole(response.data.role)
        location.href = '/'
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$refs.msActivityDialog.hideDialog()
      })
    }
  }
}
</script>

<style scoped>
.ms-sign-in {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.ms-content {
  width: 320px;
}
.ms-logo-image {
  width: 100%;
  object-fit: contain;
}
.ms-title-row {
  margin: 0 0 32px;
}
.ms-title {
  font-weight: 200;
}
.ms-text-field-row {
  padding: 8px 24px 0;
}
.ms-sign-in-button {
  width: 84%;
  margin: 36px 24px;
}
</style>
