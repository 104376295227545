<template>
  <div class="ms-file-container">
    <div
      ref="dropzone"
      @dragover="onDragOver($event)"
      @drop="onDrop($event)"
    >
      <p class="ms-text" v-if="editing">ファイルをドロップしてください。({{ types.join(', ') }})</p>
      <v-row class="ms-row">
        <h4 class="ms-filename">{{ filename }}</h4>
        <v-spacer></v-spacer>
        <v-btn v-if="editing" depressed dark @click="cancelButtonClicked" class="ms-button">
          <v-icon v-if="showButtonIcon">cancel</v-icon>CANCEL
        </v-btn>
        <v-btn v-if="editing" depressed dark @click="deleteButtonClicked">
          <v-icon v-if="showButtonIcon">delete</v-icon>REMOVE
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'editing',
    'name',
    'url',
    'types'
  ],
  data() {
    return {
      showButtonIcon: window.innerWidth >= 400,
      file: null,
      deleted: false
    }
  },
  computed: {
    filename() {
      if (this.deleted) {
        return 'なし'
      }
      if (this.file) {
        return this.file.name
      }
      if (this.name) {
        return this.name
      }
      if (this.url) {
        return this.url
      }
      return 'なし'
    }
  },
  methods: {
    result() {
      if (this.file) {
        return this.file
      }
      if (this.deleted) {
        return 'delete'
      }
      return null
    },
    cancelButtonClicked() {
      this.file = null
      this.deleted = false
    },
    deleteButtonClicked() {
      this.file = null
      this.deleted = true
    },
    onDragOver(e) {
      e.preventDefault()
    },
    onDrop(e) {
      e.preventDefault()
      if (!this.editing) {
        return
      }
      let file = e.dataTransfer.files[0]
      let ext = file.name.split('.').pop()
      if (this.types.includes(ext)) {
        this.file = e.dataTransfer.files[0]
        this.deleted = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ms-file-container {
  margin: 24px 0;
  padding: 16px;
  border: solid 1px #323232;
}
.ms-row {
  padding: 16px;
}
.ms-text {
  font-size: 14px;
}
.ms-filename {
  margin: 8px 0;
  height: 100%;
}
.ms-button {
  margin-right: 8px;
}
</style>
