import Axios from 'axios'

export default {
  axios(document) {
    let csrfToken = document.getElementsByName('csrf-token')[0].getAttribute('content')
    let axios = Axios.create({
      headers: {
        'X-CSRF-TOKEN': csrfToken,
        'access-token': this.accessToken(),
        'client': this.client(),
        'uid': this.uid(),
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    return axios
  },
  uid() {
    return localStorage.getItem('uid')
  },
  saveUid(uid) {
    localStorage.setItem('uid', uid)
  },
  accessToken() {
    return localStorage.getItem('access-token')
  },
  saveAccessToken(accessToken) {
    localStorage.setItem('access-token', accessToken)
  },
  client() {
    return localStorage.getItem('client')
  },
  saveClient(client) {
    localStorage.setItem('client', client)
  },
  email() {
    return localStorage.getItem('email')
  },
  saveEmail(email) {
    localStorage.setItem('email', email)
  },
  name() {
    return localStorage.getItem('name')
  },
  saveName(name) {
    localStorage.setItem('name', name)
  },
  role() {
    return localStorage.getItem('role')
  },
  saveRole(role) {
    localStorage.setItem('role', role)
  }
}
