<template>
  <v-app-bar app flat dark height="56px" min-height="56px" max-height="56px" color="#212121" class="ms-toolbar">

    <div @click="titleClicked">
      <h1 class="ms-title">ms.snsnap.net</h1>
    </div>

    <v-spacer></v-spacer>

    <v-menu bottom left offset-y>
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on"><v-icon>more_vert</v-icon></v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title>{{ uid }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="signOut">
          <v-list-item-title>SIGN OUT</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

  </v-app-bar>
</template>

<script>
import Common from './common.js'

export default {
  computed: {
    uid() {
      return Common.uid()
    }
  },
  methods: {
    backButtonClicked() {
      this.$router.go(-1)
    },
    titleClicked() {
      this.$router.push('/')
    },
    signOut() {
      Common.axios(document).delete('/auth/sign_out').then(response => {
        Common.saveEmail('')
        Common.saveAccessToken('')
        Common.saveUid('')
        Common.saveClient('')
        location.reload()
      }).catch(error => {
        Common.saveEmail('')
        Common.saveAccessToken('')
        Common.saveUid('')
        Common.saveClient('')
        location.reload()
      })
    }
  }
}
</script>

<style scoped>
.ms-title {
  font-size: 28px;
  font-weight: 200;
}
</style>
