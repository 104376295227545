<template>
  <v-app>
    <v-content v-if="tokenValidated" class="ms-content">
      <v-container fluid class="ms-container">
        <router-view></router-view>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import Common from './common.js'
import AppsShow from './apps_show.vue'
import AppsNew from './apps_new.vue'
import Apps from './apps.vue'
import SignIn from './sign_in.vue'

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(Vuetify)


let router = new VueRouter({
  routes: [
    { path: '/sign_in', component: SignIn },
    { path: '/apps/new', component: AppsNew },
    { path: '/apps/:id', component: AppsShow },
    { path: '/', component: Apps },
  ]
})

let store = new Vuex.Store({
})

let vuetify = new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#FFFFFF'
      }
    }
  }
})

export default {
  router,
  store,
  vuetify,
  data() {
    return {
      tokenValidated: false
    }
  },
  methods: {
    validateToken() {
      if (!Common.accessToken()) {
        this.$router.push('/sign_in')
        this.tokenValidated = true
        return
      }
      let params = {
        uid: Common.uid(),
        client: Common.client(),
        'access-token': Common.accessToken()
      }
      Common.axios(document).get('/auth/validate_token', params).then(response => {
        Common.saveEmail(response.data.data.email)
        Common.saveName(response.data.data.name)
        Common.saveRole(response.data.data.role)
        this.tokenValidated = true
      }).catch(error => {
        console.log(error)
        if (401 == error.response.status) {
          this.$router.push('/sign_in')
          this.tokenValidated = true
        }
      })
    }
  },
  mounted() {
    this.tokenValidated = false
    this.validateToken()
  }
}
</script>

<style scoped>
.ms-content {
  width: 100%;
  height: 100%;
}
.ms-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>

<style>
</style>
