<template>
  <v-container>

    <h4 class="ms-title">QR</h4>
    <p class="ms-text">
      <ul>
        <li>有効期限に設定した日付の23:59までQRコードを使用できます。</li>
        <li>有効期限を過ぎたQRコードは破棄されます。</li>
        <li>有効期限内のQRコードは有効期限を更新することができます。</li>
        <li>有効なQRコードが存在しない場合、このページを表示する際に再発行します。</li>
        <li>QRコードは複数発行できます。</li>
        <li>破棄または削除したQRコードは再利用できません。</li>
        <li>QRコードが表示されるにはファイルが添付されている必要があります。</li>
      </ul>
    </p>


    <v-layout row justify-center v-for="(t, i) in app.tokens" v-if="app.file_name">
      <div class="ms-qr-container">

        <div class="ms-control-row">
          <div class="ms-text-field-container">
            <v-text-field
              v-model="t.expires_at"
              type="date"
              label="有効期限"
              :clearable="editing"
              :disabled="!editing"
            ></v-text-field>
          </div>
          <div class="ms-delete-button">
            <v-btn v-if="editing" icon @click="deleteButtonClicked(t)"><v-icon>delete</v-icon></v-btn>
          </div>
        </div>
        <canvas v-if="t.install_url" ref="qrCanvas" class="ms-canvas"></canvas>
        <div v-if="!t.install_url" class="ms-qr-placeholder">保存後に表示</div>
      </div>
    </v-layout>


    <v-row  v-if="app.file_name">
      <v-spacer></v-spacer>
      <v-btn v-if="editing" dark depressed @click="addButtonClicked" class="ms-button-last"><v-icon>add</v-icon>ADD</v-btn>
    </v-row>


  </v-container>
</template>

<script>
import QRCode from 'qrcode'

export default {
  props: [
    'app',
    'editing'
  ],
  methods: {
    deleteButtonClicked(token) {
      let index = this.app.tokens.findIndex(t => {
        return t.id == token.id && t.install_url == token.install_url && t.expires_at == token.expires_at
      })
      this.app.tokens.splice(index, 1)
    },
    addButtonClicked() {
      let date = new Date(Date.now() + 2 * 24 * 60 * 60 * 1000)
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let str = `${y}-${m}-${d}`
      this.app.tokens.push({id: null, expires_at: str, install_url: null})
    },
    showQr() {
      if (!this.app.file_url) {
        return
      }
      setTimeout(() => {
        for (let i = 0; i < this.app.tokens.length; i++) {
          QRCode.toCanvas(this.$refs.qrCanvas[i], this.app.tokens[i].install_url, (error) => {
            if (error) {
              console.error(error)
              return
            }
          })
        }
      }, 1000)
    }
  }
}
</script>

<style scoped>
.ms-title {
  margin: 16px;
  color: #868686;
}
.ms-text {
  margin: 16px 16px 48px;
  font-size: 14px;
  color: #868686;
}
.ms-qr-container {
  width: 244px;
  margin-bottom: 48px;
}
.ms-control-row {
  display: flex;
  justify-content: space-between;
  aling-items: center;
}
.ms-text-field-container {
  width: 168px;
}
.ms-delete-button {
  margin: 12px 0;
}
.ms-canvas {
  width: 244px;
  height: 244px;
  background-color: white;
}
.ms-qr-placeholder {
  width: 244px;
  height: 244px;
  padding-top: 100px;
  text-align: center;
  color: black;
  background-color: white;
}
.ms-button-last {
  margin: 0 12px 0 8px;
}
</style>
