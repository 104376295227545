<template>
  <div>
    <ms-toolbar></ms-toolbar>
    <v-container class="ms-apps-show-content" v-if="app">


      <v-container class="ms-controls">
        <v-row>
          <v-btn v-if="!editing" :disabled="!app.file_url" depressed @click="installButtonClicked" class="ms-button-first">
            <v-icon v-if="showButtonIcon">get_app</v-icon>Install
          </v-btn>
          <v-spacer></v-spacer>
          <template >
            <v-btn v-if="!editing" dark depressed :disabled="!isAdmin" @click="editButtonClicked" class="ms-button-last">
              <v-icon v-if="showButtonIcon">edit</v-icon>Edit
            </v-btn>
            <v-btn v-if="editing" dark depressed :disabled="!isAdmin" @click="deleteButtonClicked" class="ms-button">
              <v-icon v-if="showButtonIcon">delete</v-icon>Delete
            </v-btn>
            <v-btn v-if="editing" dark depressed :disabled="!isAdmin" @click="saveButtonClicked" class="ms-button">
              <v-icon v-if="showButtonIcon">save</v-icon>SAVE
            </v-btn>
            <v-btn v-if="editing" dark depressed :disabled="!isAdmin" @click="cancelButtonClicked" class="ms-button-last">
              <v-icon v-if="showButtonIcon">cancel</v-icon>Cancel
            </v-btn>
          </template>
        </v-row>
      </v-container>


      <v-container class="ms-content" ref="msContent">

        <v-alert :value="updated" flat color="success" icon="check_circle" transition="scale-transition">
          更新しました。
        </v-alert>
        <v-alert :value="$route.query.created == 'true'" color="success" icon="check_circle" transition="scale-transition">
          作成しました。
        </v-alert>
        <v-alert :value="errors" color="error" icon="warning" transition="scale-transition">
          <template v-for="e in errors">{{ e }}<br></template>
        </v-alert>

        <apps-app
          :app="app"
          :editing="editing"
        ></apps-app>

        <ms-file-container
          ref="msFileContainer"
          :editing="editing"
          :name="app.file_name"
          :url="app.file_url"
          :types="app.app_type == 'android' ? ['apk'] : ['ipa']"
        ></ms-file-container>

        <apps-tokens
          ref="appsTokens"
          :app="app"
          :editing="editing"
        ></apps-tokens>

      </v-container>

    </v-container>


    <ms-activity-dialog ref="msActivityDialog"></ms-activity-dialog>
    <ms-delete-dialog ref="msDeleteDialog" @delete-button-clicked="dialogDeleteButtonClicked"></ms-delete-dialog>


  </div>
</template>

<script>
import Common from './common.js'
import MsToolbar from './ms_toolbar.vue'
import AppsApp from './apps_app.vue'
import MsFileContainer from './ms_file_container.vue'
import AppsTokens from './apps_tokens.vue'
import MsActivityDialog from './ms_activity_dialog.vue'
import MsDeleteDialog from './ms_delete_dialog.vue'

export default {
  components: {
    'ms-toolbar': MsToolbar,
    'apps-app': AppsApp,
    'ms-file-container': MsFileContainer,
    'apps-tokens': AppsTokens,
    'ms-activity-dialog': MsActivityDialog,
    'ms-delete-dialog': MsDeleteDialog
  },
  data() {
    return {
      isAdmin: Common.role() == 'admin',
      showButtonIcon: window.innerWidth >= 400,
      editing: false,
      updated: false,
      errors: null,
      app: null
    }
  },
  computed: {
    installUrl() {
      let token = this.app.tokens.find(t => t.install_url != null)
      if (token == null) {
        return null
      }
      return token.install_url
    }
  },
  methods: {
    editButtonClicked() {
      if (this.$route.query.created) {
        this.$router.replace({ query: '' })
      }
      this.updated = false
      this.editing = true
    },
    deleteButtonClicked() {
      this.$refs.msDeleteDialog.showDialog()
    },
    dialogDeleteButtonClicked() {
      this.deleteApp()
    },
    cancelButtonClicked() {
      this.editing = false
      this.getApp()
    },
    saveButtonClicked() {
      this.errors = null
      this.updated = false
      this.patchApp()
    },
    installButtonClicked() {
      window.open(this.installUrl)
    },
    getApp() {
      this.$refs.msActivityDialog.showDialog()
      Common.axios(document).get('/apps/' + this.$route.params.id).then(response => {
        this.app = response.data.app
        setTimeout(() => {
          this.$vuetify.goTo(this.$refs.msContent, { offset: 56 + 12 })
          this.$refs.appsTokens.showQr()
          this.$refs.msActivityDialog.hideDialog()
        }, 100)
      }).catch(error => {
        if (401 == error.response.status) {
          this.$router.push('/sign_in')
        }
        this.$refs.msActivityDialog.hideDialog()
      })
    },
    patchApp() {
      this.updated = false
      this.errors = null
      let params = {
        app: this.app
      }
      this.$refs.msActivityDialog.showDialog()
      Common.axios(document).patch('/apps/' + this.$route.params.id, params).then(response => {
        this.app = response.data.app
        let result = this.$refs.msFileContainer.result()
        if (result) {
          this.uploadFile(result)
          return
        }
        this.editing = false
        this.updated = true
        this.getApp()

      }).catch(error => {
        console.log(error)
        if (401 == error.response.status) {
          this.$router.push('/sign_in')
        }
        this.errors = []
        let errors = error.response.data.errors
        if (errors.title) {
          errors.title.forEach(e => { this.errors.push('TITLE' + e) })
        }
        if (errors.bundle_identifier) {
          errors.bundle_identifier.forEach(e => { this.errors.push('bundle-identifier' + e) })
        }
        if (errors.bundle_version) {
          errors.pref_code.forEach(e => { this.errors.push('bundle-version' + e) })
        }
        this.$refs.msActivityDialog.hideDialog()
      })
    },
    uploadFile(result) {
      let formData = new FormData();
      formData.append('file', result)
      let config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      Common.axios(document).post('/apps/' + this.app.id, formData, config).then(response => {
        this.editing = false
        this.updated = true
        this.getApp()

      }).catch(error => {
        console.log(error)
        if (401 == error.response.status) {
          this.$router.push('/sign_in')
        }
        this.$refs.msActivityDialog.hideDialog()
      })
    },
    deleteApp() {
      this.$refs.msActivityDialog.showDialog()
      Common.axios(document).delete('/apps/' + this.$route.params.id).then(response => {
        this.$router.push('/')
      }).catch(error => {
        console.log(error)
        if (401 == error.response.status) {
          this.$router.push('/sign_in')
        }
        this.$refs.msActivityDialog.hideDialog()
      })
    }
  },
  mounted() {
    this.getApp()
  }
}
</script>

<style scoped lang="scss">
.ms-apps-show-content {
  width: 100%;
  max-width: 768px;
  padding: 0;
}
.ms-controls {
  position: fixed;
  top: 56px;
  width: 100%;
  max-width: 756px;
  padding: 16px;
  background-color: #121212;
  z-index: 100;
}
.ms-button-first {
  margin: 0 0 0 12px;
}
.ms-button {
  margin: 0 0 0 8px;
}
.ms-button-last {
  margin: 0 12px 0 8px;
}
.ms-content {
  width: calc(100% - 32px);
  margin-top: 68px;
  padding: 0;
  z-index: -100;
}
.ms-qr {
  margin: 32px 0 64px;
  min-width: 320px;
  text-align: center;
}
.ms-canvas {
  height: 280px;
}
</style>
