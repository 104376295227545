<template>
  <v-dialog v-model="show" persistent max-width="240" height="200" overlay-color="#121212" overlay-opacity="0.8">
    <v-layout row justify-center class="ms-content">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="88"
        :width="2"
      ></v-progress-circular>
    </v-layout>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: ''
    }
  },
  methods: {
    showDialog(message) {
      if (message) {
        this.message = message
      }
      this.show = true
    },
    hideDialog() {
      this.show = false
    }
  }
}
</script>

<style scoped>
.ms-content {
  height: 200px;
}
</style>
